// colors
$black-dark: #4F4F4F;
$black-medium: #828282;
$black-light: #FAFAFA;
$white: #fff;

$green: #A8CE41;

//responsive
$mobile: 750px;
$tablet: 1099px;
$desktop: 1205px;
