* {
  margin: 0;
  padding: 0; }

body {
  background: $black-light; }

.container {
  margin: 0 auto;
  max-width: calc(100% - 3em);
  width: calc(1280px - 2em);

  @media screen and (max-width: $mobile) {
    max-width: calc(100% - 40px);
    margin: 1em auto; } }

ul {
  list-style: none;
  margin: 0; }

.internal-page {
  background: $white;
  margin-top: 1em;
  padding: 4em;
  @media screen and (max-width: $mobile) {
    padding: 2em; }

  img {
    height: auto;
    margin: 2em 0;
    width: 100%; } }

.side-img {
  width: 50%;
  @media screen and (max-width: $mobile) {
    width: 100%; }
  &-left {
    @extend .side-img;
    float: left;
    margin-right: 4em; }
  &-right {
    @extend .side-img;
    float: right;
    margin-left: 4em; } }
