.blog {
  display: flex;
  margin-top: 1em;
  @media screen and (max-width: $mobile) {
    flex-flow: column; }

  &-wrap {
    margin-right: 1em;
    width: 70%;
    @media screen and (max-width: $desktop) {
      width: 50%; }
    @media screen and (max-width: $mobile) {
      margin-bottom: 3em;
      width: 100%; } }

  &-post-blocks {
    display: flex;
    margin-bottom: 2em;
    flex-wrap: wrap;

    .blog-post {
      background: $white;
      display: flex;
      flex-flow: column;
      height: 45em;
      margin-bottom: 1em;
      max-width: 425px;
      width: 49%;
      @media screen and (max-width: $desktop) {
        height: inherit;
        max-width: inherit;
        width: 100%; }

      &:nth-child(odd) {
        margin-right: 1em;
        @media screen and (max-width: $desktop) {
          margin-right: 0; } }

      img {
        width: 100%; }

      .text {
        display: flex;
        flex-flow: column;
        padding: 1em;
        justify-content: space-between;
        height: inherit; }

      .author {
        font-style: italic;
        font-size: .85em; }
      .title {
        font-size: 2em;
        line-height: 1.2em;
        @media screen and (max-width: $mobile) {
          font-size: 1.5em; } }
      .date {
        font-size: .85em; }

      &__footer {
        align-items: center;
        display: flex;
        justify-content: space-between; } } }

  &-infocta {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    width: 30%;
    &__block {
      align-items: center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      margin-bottom: 1em;
      min-height: 20em;
      text-decoration: none;
      width: 100%; }
    @media screen and (max-width: $mobile) {
      width: 100%; }

    .cta-etsy {
      background-image: url('/assets/images/block-cta/cta-bg-insta.jpg'); }

    .cta-youtube {
      background-image: url('/assets/images/block-cta/cta-bg-youtube.jpg'); }

    .cta-instagram {
      background-image: url('/assets/images/block-cta/cta-bg-insta.jpg'); } }

  .post-page {
    background: $white;
    padding: 4em;
    @media screen and (max-width: $mobile) {
      padding: 2em; }

    img {
      height: auto;
      margin: 2em 0;
      width: 100%; } }

  .layout-post {
    margin-right: 0;
    width: 100%; } }


.blog-extra-posts {
  margin-top: 5em;
  .blog-post-blocks {
    flex-wrap: nowrap;
    @media screen and (max-width: $mobile) {
      flex-wrap: wrap; }
    .blog-post {
      margin-right: 1em;
      &:last-of-type {
        margin-right: 0; } } } }
