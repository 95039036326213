.instafeed {
  @extend .container;
  height: 210px;
  margin-top: 5em;
  overflow: hidden;

  &_wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;

    img {
      max-width: 200px; } } }

footer {
  @extend .container;
  align-items: center;
  border-bottom: 2px solid $black-dark;
  border-top: 2px solid $black-dark;
  display: flex;
  justify-content: space-between;
  padding: 2em 0;
  margin-top: 5em;
  @media screen and (max-width: $mobile) {
    flex-flow: column; } }

.end {
  text-align: center;
  padding: 2em;
  font-size: .8em;
  @media screen and (max-width: $mobile) {
    margin-bottom: 4em; } }
