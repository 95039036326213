input,
input[type=email],
.form-select {
  background: $black-light;
  border: 2px solid $black-dark;
  box-sizing: border-box;
  color: $black-dark;
  display: inline-block;
  font-family: $font-default;
  font-size: 1.2em;
  outline: 0;
  padding: 1em;
  resize: none;
  width: 100%;

  &::placeholder {
    color: $black-dark;
    opacity: 1; }

  &:-ms-input-placeholder {
    color: $black-dark; }


  &::-ms-input-placeholder {
    color: $black-dark; } }

input[type=submit] {
  &:hover {
    background: $green;
    color: $white;
    cursor: pointer; } }

.newsletter__form .parsley-errors-list {
  position: absolute;
  padding: .5em 0;

  .parsley-required {
    font-weight: bold;
    color: #f25c5c; } }
