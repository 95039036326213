.menu-tree {
  @extend .container;
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 2em 0;
  .logo {
    margin-bottom: 1em; }
  .menu__links {
    display: flex;
    margin: 1em;
    min-width: 90%;
    .btn-dark {
      text-align: center;
      width: 100%; } } }
