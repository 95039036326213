// shop cta
.shop-ctas {
  margin-top: 2em;
  &__wrap {
    @extend .container;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    @media screen and (max-width: $mobile) {
      flex-direction: column; } }

  &__img {
    max-width: 405px;
    @media screen and (max-width: $desktop) {
      width: 100%; } } }

//blog posts and media
.posts-and-media-cta {
  @extend .container;
  display: flex;
  margin-top: 5em;
  @media screen and (max-width: $mobile) {
    flex-flow: column; }

  .media-cta {
    align-items: center;
    background-image: url('/assets/images/block-cta/cta-bg-youtube.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    width: 34%;

    @media screen and (max-width: $desktop) {
      width: 50%; }
    @media screen and (max-width: $mobile) {
      width: 100%; }

    &__home {
      align-items: center;
      display: flex;
      height: 100%;
      min-height: 30em;
      text-decoration: none;
      width: 100%;

      .btn {
        height: fit-content; } } } }


.btn-bgline-block {
    position: relative;
    display: flex;

    .btn-bgline {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      position: absolute;
      background: $black-medium;
      top: 1.7em; } }

// media links
.media-ctas {
  background-image: url('/assets/images/block-cta/bg-squares.svg');
  background-position: center;
  background-size: inherit;
  margin-top: 5em;

  &__wrap {
    @extend .container;
    display: flex;
    justify-content: space-between;
    padding: 5em 0;

    @media screen and (max-width: $tablet) {
      flex-direction: column; } }


  &__cta {
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 20em;
    justify-content: center;
    text-decoration: none;
    width: 32%;
    @media screen and (max-width: $desktop) {
      margin: 1em 0;
      width: 100%; }

    @media screen and (max-width: $mobile) {
      flex-direction: column; } }

  &__shop {
    background-image: url('/assets/images/block-cta/cta-bg-shop.jpg'); }
  &__faq {
    background-image: url('/assets/images/block-cta/cta-bg-popcorn.jpg'); }
  &__insta {
    background-image: url('/assets/images/block-cta/cta-bg-insta.jpg'); } }



// about
.about {
  @extend .container;
  display: flex;
  margin-top: 5em;
  @media screen and (max-width: $mobile) {
    flex-flow: column; }

  &__img {
    width: 50%;
    @media screen and (max-width: $mobile) {
      width: 100%; } }

  &__txt {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-left: 2em;

    p {
      margin: 2em 0; } }

  .btn-singleborder {
    margin-left: 0; } }

// newsletter
.newsletter {
  background-image: url('/assets/images/block-cta/bg-squares.svg');
  background-position: center;
  background-size: inherit;
  margin-top: 5em;
  padding: 5em 0;

  &__wrap {
    @extend .container;
    align-items: center;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $tablet) {
      flex-direction: column; }

    h3 {
      font-family: $font-default;
      font-weight: bold;
      font-size: 2em;
      text-transform: uppercase; } }

  &__description {
    width: 45%;
    @media screen and (max-width: $tablet) {
      width: 100%; } }

  &__form {
    position: relative;
    width: 45%;
    @media screen and (max-width: $tablet) {
      width: 100%; }

    .form-alert {
      background: #97b838;
      color: white;
      padding: 1em;
      visibility: hidden;
      font-weight: bold; }

    .visible {
      visibility: visible; }

    .hidden {
      visibility: hidden;
      display: none; }

    .newsletter__input {
      width: 82.5%;
      border-right: 0;
      @media screen and (max-width: $tablet) {
        width: 80%; }
      @media screen and (max-width: $mobile) {
        width: 50%; } }

    .newsletter__btn {
      position: absolute;
      width: auto; } } }
