.hellobar {
  display: block;
  height: 35px;

  &__link {
    background: $green;
    display: block;
    padding: .5em 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%; } }

.hero {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100%;

  header {
    @extend .container;
    background: white;
    margin-top: 1em;
    padding: 2.5em 0;
    @media screen and (max-width: $mobile) {
      padding: 1em 0; }

    .menu {
      align-items: center;
      display: flex;
      justify-content: space-evenly;

      &__links {
        @media screen and (max-width: $mobile) {
          display: none; } }
      a {
        font-weight: bold;
        text-decoration: none; } }

    .menu-responsive {
      background: $green;
      bottom: 0;
      display: none;
      position: fixed;
      right: 0;
      height: 60px;
      justify-content: space-between;
      width: 100%;
      z-index: 990;
      @media screen and (max-width: $mobile) {
        display: flex; }

      .menu-responsive__li {
        display: flex;
        width: 50vw;
        background: $green;
        height: 100%;
        align-items: center;
        &:last-of-type {
          background: darken($green, 15%); }

        a {
          color: white;
          text-align: center;
          letter-spacing: 8px;
          width: 100%;
          text-decoration: none;
          text-transform: uppercase; } } } }

  h1 {
    font-family: $font-title;
    margin-top: 1rem;
    text-align: center;
    width: 50%;
    @media screen and (max-width: $mobile) {
      margin-top: 0;
      width: 100%; } } }
