.btn {
  cursor: pointer;
  padding: 1em 2em;
  background: white;
  position: relative;
  z-index: 10;
  margin: 0 auto;
  letter-spacing: 8px;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    background: $green;
    color: $white; }

  &-dobbleborder {
    @extend .btn;
    border: 2px solid $black-medium;
    &:hover {
      border: 2px solid $green; } }

  &-dark {
    @extend .btn;
    background: $black-dark;
    border: 2px solid $black-dark;
    color: white;
    &:hover {
      border: 2px solid $green; } }

  &-singleborder {
    @extend .btn;
    border: 1px solid $black-medium;
    &:hover {
      border: 1px solid $green; } } }
